import * as MixpanelHomePageActions from '@analytics/mixpanel/actions/HomePage';
import { useRouter } from 'next/router';
import React, { useEffect, useRef, useState } from 'react';
import ClickAwayListener from 'react-click-away-listener';
import { AccordionButton, AccordionDiv, AccordionText, AccordionTitle, CloseIcon, Container } from './Accordion.styles';
import { AccordionProps } from './Accordion.types';

const Accordion = ({ title, slug, children }: AccordionProps): JSX.Element => {
  const router = useRouter();
  const [height, setHeight] = useState(0);
  const [isActive, setIsActive] = useState(false);

  const content = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setHeight(content.current?.scrollHeight || 50);
  }, [content.current?.scrollHeight]);

  function toggleAccordion() {
    if (isActive) {
      MixpanelHomePageActions.onFAQItemCollapsed({ router, slug });
    } else {
      MixpanelHomePageActions.onFAQItemExpanded({ router, slug });
    }
    setIsActive(!isActive);
  }

  return (
    <ClickAwayListener
      onClickAway={() => {
        if (isActive) {
          MixpanelHomePageActions.onFAQItemCollapsed({ router, slug });
        }
        setIsActive(false);
      }}
    >
      <Container>
        <AccordionButton onClick={toggleAccordion}>
          <AccordionTitle>{title}</AccordionTitle>
          <CloseIcon isactive={isActive} />
        </AccordionButton>
        <AccordionDiv ref={content} contentHeight={height} isactive={isActive}>
          <AccordionText>{children}</AccordionText>
        </AccordionDiv>
      </Container>
    </ClickAwayListener>
  );
};

export default Accordion;
