import { SVGProps } from 'react';

export default function SiteVideoPlay(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg {...props} width='100' height='100' viewBox='0 0 100 100' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g filter='url(#filter0_d_2143:20854)'>
        <path
          d='M80.9515 42.1331L22.9269 13.1194C22.2672 12.7915 21.5348 12.6372 20.7989 12.6712C20.063 12.7053 19.3479 12.9265 18.7213 13.3139C18.0947 13.7013 17.5773 14.2421 17.218 14.8852C16.8587 15.5284 16.6694 16.2525 16.668 16.9892V75.0138C16.6691 75.7506 16.8583 76.4748 17.2176 77.118C17.5769 77.7612 18.0944 78.302 18.7212 78.6892C19.3479 79.0765 20.0632 79.2973 20.7992 79.3308C21.5351 79.3644 22.2675 79.2095 22.9269 78.8808L80.9515 49.8699C81.6697 49.5106 82.2738 48.9584 82.696 48.2752C83.1182 47.5919 83.3418 46.8047 83.3418 46.0015C83.3418 45.1984 83.1182 44.4111 82.696 43.7278C82.2738 43.0446 81.6697 42.4924 80.9515 42.1331Z'
          fill='white'
        />
      </g>
      <defs>
        <filter
          id='filter0_d_2143:20854'
          x='-6'
          y='-6'
          width='112'
          height='112'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha' />
          <feOffset dy='4' />
          <feGaussianBlur stdDeviation='8' />
          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.125 0' />
          <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_2143:20854' />
          <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_2143:20854' result='shape' />
        </filter>
      </defs>
    </svg>
  );
}
