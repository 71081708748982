import ProfileCard from '@features/home/ProfileCard/ProfileCard';
import useTranslation from 'next-translate/useTranslation';
import { FEATURED_TITLE, I18N_HOME_CONTEXT } from './FeaturedSection.constants';
import { CelebrityCard, CelebrityGrid, Container, FeaturedTitle } from './FeaturedSection.styles';
import { FeaturedSectionProps } from './FeaturedSection.types';

const FeaturedSection = ({ featuredGridItems, mobileFeaturedCount, featuredCount }: FeaturedSectionProps): JSX.Element => {
  const { t } = useTranslation(I18N_HOME_CONTEXT);

  return (
    <Container>
      <FeaturedTitle>{t(FEATURED_TITLE)}</FeaturedTitle>
      <CelebrityGrid>
        {featuredGridItems.map((item, index) => {
          return (
            <CelebrityCard
              key={item.slug}
              grid='featured'
              featured={index < featuredCount}
              hiddenOnMobile={mobileFeaturedCount <= index && index < featuredCount}
            >
              <ProfileCard key={item.slug} {...item} featured />
            </CelebrityCard>
          );
        })}
      </CelebrityGrid>
    </Container>
  );
};

export default FeaturedSection;
