
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import * as HomePageAPI from '@api/HomePage';
import { BaseResponse } from '@api/model/base-response';
import Home from '@layouts/Home/Home';
import { HomeProps } from '@layouts/Home/Home.types';
import * as Sentry from '@sentry/nextjs';
import { GetStaticPropsContext } from 'next';

const exportResponse = (response: BaseResponse<HomePageAPI.HomePageResponse>): HomePageAPI.HomePageResponse => {
  if (!response.success || !response.data || !Array.isArray(response.data)) {
    Sentry.captureMessage('Home Page load failed due to the returned object is something different than expected', {
      extra: {
        _response_data: response.data,
        _response_code: response.code,
        _response_errors: response.errors,
        _response_message: response.message,
      },
    });

    return [];
  }

  return response.data;
};

 const _getStaticProps = async function (context: GetStaticPropsContext): Promise<any> {
  const response = await HomePageAPI.Get(context.locale);
  const allCelebsInitial = exportResponse(response);
  const isEnglish = context.locale == 'en';

  return {
    props: {
      allCelebsInitial,
      ssrLocale: context.locale,
      videoItemsInitial: [
        {
          slug: 'chico',
          fullname: isEnglish ? 'Chico' : 'شيكو',
          thumbnailPhoto: 'https://ik.imagekit.io/yela/Production/HomePage_Videos/Video_Thumbnail/chico_GbD6TcVKhnz.png',
          photo: 'https://ik.imagekit.io/yela/Production/HomePage_Videos/Video_Image/Chico1_7esSXpzuBt2.jpg',
          video: 'https://ik.imagekit.io/yela/Production/HomePage_Videos/Video/chico_gMY0wAazUv.mp4',
          heading: isEnglish ? 'Apology message' : 'رسالة اعتذار',
          subtext: isEnglish ? 'Gift to a friend' : 'هدية إلى صديق',
        },
        {
          slug: 'amina_khalil',
          fullname: isEnglish ? 'Amina Khalil' : 'أمينة خليل',
          thumbnailPhoto: 'https://ik.imagekit.io/yela/Production/HomePage_Videos/Video_Thumbnail/amina_t4x6Javbh.png',
          photo: 'https://ik.imagekit.io/yela/Production/HomePage_Videos/Video_Image/amina_khalil_4__homepage_Oq3x6n7OQP4.jpg',
          video: 'https://ik.imagekit.io/yela/Production/HomePage_Videos/Video/amina_X_KEjuANcA.mp4',
          heading: isEnglish ? 'Words of support' : 'رسالة تشجيعية',
          subtext: isEnglish ? 'Gift to a friend' : 'هدية إلى صديق',
        },
        {
          slug: 'dina_el_sherbiny',
          fullname: isEnglish ? 'Dina El Sherbiny' : 'دينا الشربيني',
          thumbnailPhoto: 'https://ik.imagekit.io/yela/Production/HomePage_Videos/Video_Thumbnail/dina_QMiVXvH7hQ.png',
          photo: 'https://ik.imagekit.io/yela/Production/HomePage_Videos/Video_Image/Copy_of_Dina_Sherbiny_hwGAcpPADO.jpg',
          video: 'https://ik.imagekit.io/yela/Production/HomePage_Videos/Video/dina_tOumt0ICGtD.mp4',
          heading: isEnglish ? 'Birthday message' : 'رسالة عيد ميلاد',
          subtext: isEnglish ? 'Gift to a friend' : 'هدية إلى صديق',
        },
        {
          slug: 'hesham_maged',
          fullname: isEnglish ? 'Hesham Maged' : 'هشام ماجد',
          thumbnailPhoto: 'https://ik.imagekit.io/yela/Production/HomePage_Videos/Video_Thumbnail/hesham_GqcJZTzyW.png',
          photo: 'https://ik.imagekit.io/yela/Production/HomePage_Videos/Video_Image/Hesham_Maged.home_page_lPAb-wO0bK.jpg',
          video: 'https://ik.imagekit.io/yela/Production/HomePage_Videos/Video/hesham_prZ5T_e9U3m.mp4',
          heading: isEnglish ? 'Congratulations message' : 'رسالة تهنئة ',
          subtext: isEnglish ? 'Gift to a friend' : 'هدية إلى صديق',
        },
      ],
    },
    revalidate: 300,
  };
};

export default function HomePage(props: HomeProps): JSX.Element {
  return <Home allCelebsInitial={props.allCelebsInitial} videoItemsInitial={props.videoItemsInitial} ssrLocale={props.ssrLocale} />;
}


// @ts-ignore
    export async function getStaticProps(ctx) {
// @ts-ignore
        let res = _getStaticProps(ctx)
// @ts-ignore
        if(typeof res.then === 'function') res = await res
// @ts-ignore
        return {
// @ts-ignore
          ...res,
// @ts-ignore
          props: {
// @ts-ignore
            ...(res.props || {}),
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/index',
// @ts-ignore
              loaderName: 'getStaticProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  