import BannerArrow from '@assets/arrow_2';
import BannerTag from '@assets/tag_cash';
import { Body1, Heading6, Subtitle3 } from '@components/Texts/Texts';
import tw, { styled } from 'twin.macro';

export const HeroText = styled.h1`
  ${tw`text-light-high font-bold text-center`}
  ${tw`sm:text-headline-4`}
  ${tw`md:text-headline-4`}
  ${tw`lg:text-headline-2`};
  ${tw`xl:text-headline-2`};
`;

export const YellowHeroText = styled.span`
  ${tw`text-brand-primary`}
`;

export const HowSection = styled.section`
  ${tw`flex flex-col col-span-full`}
  ${tw`sm:hidden`}
  ${tw`md:items-center md:pt-6 md:pb-8`}
  ${tw`lg:justify-center lg:pt-12 lg:pb-15`}
  ${tw`xl:justify-center xl:pt-12 xl:pb-15`}
`;

export const HowTitle = styled.p`
  ${tw`text-light-high text-center font-bold`}
  ${tw`sm:text-headline-4`}
  ${tw`md:text-headline-4`}
  ${tw`lg:text-headline-2`}
  ${tw`xl:text-headline-2`}
`;

export const HowFlexbox = styled.div`
  ${tw`flex items-center`}
  ${tw`sm:flex-col sm:space-y-5 sm:pt-6`}
  ${tw`md:flex-col md:space-y-5 md:pt-6`}
  ${tw`lg:justify-evenly lg:pt-8`}
  ${tw`xl:justify-evenly xl:pt-8`}
`;

export const HowStep = styled.div`
  ${tw`flex flex-col items-center`}
`;

export const HowStepTitle = styled(Heading6)`
  ${tw`text-light-medium pb-2`}
`;

export const HowStepText = styled(Body1)`
  ${tw`text-light-low`}
`;

export const HowSectionSmall = styled.section`
  ${tw`col-span-full pt-3 pb-3`}
  ${tw`md:hidden`}
  ${tw`lg:hidden`}
  ${tw`xl:hidden`}
`;

export const HowTitleSmall = styled.p`
  ${tw`text-light-high font-bold text-headline-4 mb-4`}
`;

export const HowStepperSmall = styled.div`
  ${tw`flex flex-col text-light-low`}
  ${tw`ms-3`}
`;

export const HowStepSmall = styled.div<{ noBorder?: boolean }>`
  ${tw`relative pb-3 ps-4 border-s-2`}
  border-color: rgb(32, 32, 37);
  ${props => props.noBorder && tw`border-transparent`};
`;

export const HowStepNumberSmall = styled.div`
  background-color: rgb(32, 32, 37);
  border-radius: 64px;
  padding-left: 12px;
  padding-right: 12px;
  ${tw`absolute pt-1 pb-1 text-light-high text-subtitle-2 font-bold`}
  [dir='rtl'] & {
    right: -17px;
  }
  [dir='ltr'] & {
    left: -17px;
  }
`;

export const HowStepTitleSmall = styled(Heading6)`
  ${tw`text-light-high pb-1`}
`;

export const HowStepTextSmall = styled(Body1)``;

export const EmailOptInSection = styled.section`
  background-color: rgba(32, 32, 37, 1);
  ${tw`sm:py-6 sm:px-2`}
  ${tw`md:py-6 md:px-2`}
  ${tw`lg:pt-8 lg:pb-6 lg:justify-center`}
  ${tw`xl:pt-8 xl:pb-6 xl:justify-center`}
`;

export const InnerSection = styled.div`
  ${tw`flex flex-col`}
  ${tw`lg:w-1/2 lg:mx-auto`}
  ${tw`xl:w-1/2 xl:mx-auto`}
`;

export const BottomSectionDivider = styled.div`
  height: 2px;
  background: #303036;
  ${tw`my-4`}
`;

export const EmailOptInBanner = styled.div`
  z-index: 100;
  background-color: rgba(24, 24, 27, 1);
  max-width: 1920px;
  ${tw`fixed top-0 h-6 w-full flex justify-center items-center`}
  ${tw`lg:h-8 xl:h-8`}
`;

export const BannerCTA = styled.div`
  ${tw`cursor-pointer flex flex-row items-center`}
`;

export const BannerJoinText = styled(Subtitle3)`
  ${tw`text-light-low me-1`}
  ${tw`lg:text-button lg:font-semibold`}
  ${tw`xl:text-button xl:font-semibold`}
`;

export const BannerMainText = styled(Subtitle3)`
  ${tw`text-light-high ms-1 me-2`}
  ${tw`lg:text-button lg:font-semibold`}
  ${tw`xl:text-button xl:font-semibold`}
`;

export const BannerArrowIcon = styled(BannerArrow)`
  ${tw`sm:h-2 sm:w-2`}
  ${tw`md:h-2 md:w-2`}
  [dir='rtl'] & {
    transform: scaleX(-1);
  }
`;

export const BannerTagIcon = styled(BannerTag)``;
