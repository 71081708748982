import * as MixpanelHomePageActions from '@analytics/mixpanel/actions/HomePage';
import { LogInResponse } from '@api/Auth';
import * as HomePageAPI from '@api/HomePage';
import { getUserData } from '@common/utils';
import HowWorksSection from '@features/common/HowWorksSection/HowWorksSection';
import SiteVideoPlayer from '@features/common/SiteVideoPlayer/SiteVideoPlayer';
import { VideoItem } from '@features/common/SiteVideoPlayer/SiteVideoPlayer.types';
import EmailOptInModal from '@features/home/EmailOptInModal/EmailOptInModal';
import Headline from '@features/home/Headline/Headline';
import { ProfileCardProps } from '@features/home/ProfileCard/ProfileCard.types';
import ScrollTopButton from '@features/home/ScrollTopButton/ScrollTopButton';
import CelebritySection from '@features/home/Sections/CelebritySection/CelebritySection';
import FAQSection from '@features/home/Sections/FAQSection/FAQSection';
import FeaturedSection from '@features/home/Sections/FeaturedSection/FeaturedSection';
import InformationSection from '@features/home/Sections/InformationSection/InformationSection';
import VideosSection from '@features/home/Sections/VideosSection/VideosSection';
import TalentButton from '@features/home/TalentButton/TalentButton';
import BaseLayout from '@layouts/Base/BaseLayout';
import * as Sentry from '@sentry/nextjs';
import useTranslation from 'next-translate/useTranslation';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import {
  HER0_TEXT_1,
  HER0_TEXT_2,
  HER0_TEXT_YELLOW,
  I18N_HOME_CONTEXT,
  OPT_IN_BANNER_JOIN_TEXT,
  OPT_IN_BANNER_MAIN_TEXT,
} from './Home.constants';
import {
  BannerArrowIcon,
  BannerCTA,
  BannerJoinText,
  BannerMainText,
  BannerTagIcon,
  BottomSectionDivider,
  EmailOptInBanner,
  EmailOptInSection,
  HeroText,
  InnerSection,
  YellowHeroText,
} from './Home.styles';
import { HomeProps } from './Home.types';

const EmailOptInForm = dynamic(() => import('@features/home/EmailOptInForm/EmailOptInForm'));

const Home = ({ allCelebsInitial, ssrLocale, videoItemsInitial }: HomeProps): JSX.Element => {
  const featuredCount = 4;
  const mobileFeaturedCount = 2;
  const { locale } = useRouter();
  const router = useRouter();
  const { t } = useTranslation(I18N_HOME_CONTEXT);
  const emailOptInRef = useRef(null);
  const [featuredGridItems, setFeaturedGridItems] = useState<ProfileCardProps[]>(allCelebsInitial.slice(0, featuredCount));
  const [allGridItems, setAllGridItems] = useState<ProfileCardProps[]>(allCelebsInitial);
  const [user, setUser] = useState<LogInResponse>({} as LogInResponse);
  const [isShowEmailOptInModal, setIsShowEmailOptInModal] = useState<boolean>(false);
  const [isShowSiteVideoPlayer, setIsShowSiteVideoPlayer] = useState<boolean>(false);
  const [selectedVideoItem, setSelectedVideoItem] = useState<VideoItem | undefined>(undefined);

  useEffect(() => {
    MixpanelHomePageActions.onHomePageLoaded({ router });
    const user = getUserData(document.cookie);
    if (user) setUser(user);
  }, []);

  useEffect(() => {
    const getHomePage = async () => {
      const response = await HomePageAPI.Get();
      let allCelebs: ProfileCardProps[] = allCelebsInitial;
      if (!response.success || !response.data || !Array.isArray(response.data)) {
        Sentry.captureMessage('Home Page load failed due to the returned object is something different than expected - [useEffect]', {
          extra: {
            _response_data: response.data,
            _response_code: response.code,
            _response_errors: response.errors,
            _response_message: response.message,
          },
        });
      } else {
        allCelebs = response.data;
      }

      setAllGridItems(allCelebs);
      setFeaturedGridItems(allCelebs.slice(0, featuredCount));
    };
    getHomePage();
  }, [locale]);

  const scrollTo = (refCurrent?: any) => {
    if (!refCurrent) {
      return window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }
    refCurrent.scrollIntoView({ behavior: 'smooth' });
  };

  const bannerCTAClick = () => {
    MixpanelHomePageActions.onHeaderEmailOptinLinkClicked({ router });
    scrollTo(emailOptInRef.current);
    document.getElementById('email-opt-in-input')?.focus();
  };

  const EmailOptIn = (
    <EmailOptInSection ref={emailOptInRef}>
      <InnerSection>
        <EmailOptInForm
          openPopup={() => {
            setIsShowEmailOptInModal(true);
          }}
        />
        <BottomSectionDivider />
        <TalentButton />
      </InnerSection>
    </EmailOptInSection>
  );

  const OptInBanner = (
    <EmailOptInBanner>
      <BannerTagIcon />
      <BannerMainText>{t(OPT_IN_BANNER_MAIN_TEXT)}</BannerMainText>
      <BannerCTA onClick={bannerCTAClick}>
        <BannerJoinText>{t(OPT_IN_BANNER_JOIN_TEXT)}</BannerJoinText>
        <BannerArrowIcon />
      </BannerCTA>
    </EmailOptInBanner>
  );

  return (
    <BaseLayout
      showFooter
      aboveHeader={OptInBanner}
      headerProps={{ userName: user.name, setUser }}
      underGrid={EmailOptIn}
      infoOG={{
        ssrLocale,
      }}
    >
      <ScrollTopButton onClick={() => scrollTo()} />
      <EmailOptInModal
        toDisplay={isShowEmailOptInModal}
        closePopup={() => {
          setIsShowEmailOptInModal(false);
        }}
      />
      <SiteVideoPlayer
        videoItem={selectedVideoItem}
        show={isShowSiteVideoPlayer}
        close={() => {
          setIsShowSiteVideoPlayer(false);
        }}
      />
      <Headline
        text={
          <HeroText>
            {t(HER0_TEXT_1)}
            {` `} <YellowHeroText>{t(HER0_TEXT_YELLOW)}</YellowHeroText> {` `}
            {t(HER0_TEXT_2)}
          </HeroText>
        }
      />
      <FeaturedSection featuredGridItems={featuredGridItems} mobileFeaturedCount={mobileFeaturedCount} featuredCount={featuredCount} />
      <VideosSection
        videoItemsInitial={videoItemsInitial}
        setIsShowSiteVideoPlayer={setIsShowSiteVideoPlayer}
        setSelectedVideoItem={setSelectedVideoItem}
      />
      <CelebritySection allGridItems={allGridItems} mobileFeaturedCount={mobileFeaturedCount} featuredCount={featuredCount} />
      <HowWorksSection />
      <InformationSection />
      <FAQSection />
    </BaseLayout>
  );
};

export default Home;
