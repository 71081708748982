import Close from '@assets/close';
import { Body1, Subtitle1 } from '@components/Texts/Texts';
import isPropValid from '@emotion/is-prop-valid';
import tw, { styled } from 'twin.macro';
import { AccordionDivProps, CloseIconState } from './Accordion.types';

export const Container = styled.div`
  ${tw`flex flex-col py-2`}
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
`;

export const AccordionButton = styled.button`
  ${tw`flex items-center justify-between pr-2`}
  &:focus {
    outline: none;
  }
  @supports selector(:focus-visible) {
    &:focus-visible {
      outline: 1px solid whitesmoke;
    }
  }
`;

export const AccordionTitle = styled(Subtitle1)`
  ${tw`text-light-high text-start`}
`;

export const CloseIcon = styled(Close, { shouldForwardProp: isPropValid })<CloseIconState>`
  min-width: 16px;
  fill: rgba(112, 112, 115, 1);
  transition: transform 350ms ease-in-out;
  transform: ${p => (p.isactive ? 'rotate(0deg)' : 'rotate(135deg)')};
`;

export const AccordionDiv = styled.div<AccordionDivProps>`
  ${tw`flex overflow-hidden`}
  transition: height 350ms ease-in-out;
  height: ${p => (p.isactive ? p.contentHeight + 24 : 0)}px;
`;

export const AccordionText = styled(Body1)`
  ${tw`text-light-medium pt-2 mr-1`}
`;
