import { SVGProps } from 'react';

const Arrow2 = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg {...props} width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M16.172 11.0002L10.808 5.63617L12.222 4.22217L20 12.0002L12.222 19.7782L10.808 18.3642L16.172 13.0002H4V11.0002H16.172Z'
        fill='white'
        fillOpacity='0.6'
      />
    </svg>
  );
};

export default Arrow2;
