import Arrow from '@assets/arrow_2';
import { Overline, Subtitle2 } from '@components/Texts/Texts';
import tw, { styled } from 'twin.macro';

export const TalentButtonContainer = styled.a`
  background: #303036;
  ${tw`cursor-pointer flex justify-between items-center py-2 px-3 rounded-full`}
`;

export const TalentButtonTextContainer = styled.div`
  ${tw`flex items-center me-2`}
`;

export const TalentButtonTextOne = styled(Overline)`
  ${tw`text-brand-primary me-2`}
`;

export const TalentButtonTextTwo = styled(Subtitle2)`
  ${tw`text-light-high`}
`;

export const TalentButtonArrow = styled(Arrow)`
  ${tw`h-2 w-2`}
  min-height: 16px;
  min-width: 16px;
  [dir='rtl'] & {
    transform: scaleX(-1);
  }
`;
