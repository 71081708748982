import { Container, HeadlineText, Shading } from './Headline.styles';
import { HeadlineProps } from './Headline.types';

const Headline = ({ text }: HeadlineProps): JSX.Element => {
  return (
    <Container>
      <Shading>
        <HeadlineText>{text}</HeadlineText>
      </Shading>
    </Container>
  );
};

export default Headline;
