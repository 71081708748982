import GreenCheck from '@assets/check_circle';
import tw, { styled } from 'twin.macro';

export const GreenCheckIcon = styled(GreenCheck)`
  ${tw`pe-1`}
`;

export const Container = styled.section`
  ${tw`relative grid col-span-full`}
  ${tw`lg:grid-cols-12 lg:gap-x-3 lg:py-12`}
  ${tw`xl:grid-cols-12 xl:gap-x-3 xl:py-12`}
`;

export const GrayBackground = styled.div`
  ${tw`absolute inset-y-0`}
  ${tw`sm:-left-2 sm:-right-2`}
  ${tw`md:-left-3 md:-right-3`}
  ${tw`lg:-left-8 lg:-right-8`}
  ${tw`xl:-left-8 xl:-right-8`}
  background-color: rgb(32, 32, 37);
`;

export const InformationTextContainer = styled.div`
  ${tw`flex justify-around`}
  ${tw`sm:flex-col sm:py-4 sm:space-y-3`}
  ${tw`md:flex-col md:py-4 md:space-y-3`}
  ${tw`lg:col-start-2 lg:col-end-12`}
  ${tw`xl:col-start-2 xl:col-end-12`}
`;

export const InformationText = styled.p`
  ${tw`flex items-center text-light-high`}
  ${tw`sm:text-subtitle-1`}
  ${tw`md:text-subtitle-1`}
  ${tw`lg:text-headline-6`}
  ${tw`xl:text-headline-6`}
  z-index: 2;
`;
