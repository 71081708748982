import ProfileCard from '@features/home/ProfileCard/ProfileCard';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import FastMarquee from 'react-fast-marquee';
import {
  CELEBRITY_TITLE_1,
  CELEBRITY_TITLE_2,
  CELEBRITY_TITLE_3,
  CELEBRITY_TITLE_BLACK_1,
  CELEBRITY_TITLE_BLACK_2,
  I18N_HOME_CONTEXT,
  MARQUEE_ASK_QUESTION,
  MARQUEE_BIRTHDAY,
  MARQUEE_GET_WELL_SOON,
  MARQUEE_THANK_YOU,
  MARQUEE_WEDDING,
  MARQUEE_WEDDING_INVITATION,
} from './CelebritySection.constants';
import {
  BlackText,
  CelebrityCard,
  CelebrityGrid,
  CelebritySectionTitle,
  Container,
  Gray2Background,
  MarqueeEntry,
  OccasionsMarqueeContainer,
  YellowBackground,
} from './CelebritySection.styles';
import { CelebritySectionProps } from './CelebritySection.types';

const CelebritySection = ({ allGridItems, mobileFeaturedCount, featuredCount }: CelebritySectionProps): JSX.Element => {
  const { locale } = useRouter();
  const { t } = useTranslation(I18N_HOME_CONTEXT);

  const MarqueeEntries = (
    <>
      <MarqueeEntry>•</MarqueeEntry>
      <MarqueeEntry>{t(MARQUEE_BIRTHDAY)}</MarqueeEntry>
      <MarqueeEntry>•</MarqueeEntry>
      <MarqueeEntry>{t(MARQUEE_WEDDING_INVITATION)}</MarqueeEntry>
      <MarqueeEntry>•</MarqueeEntry>
      <MarqueeEntry>{t(MARQUEE_GET_WELL_SOON)}</MarqueeEntry>
      <MarqueeEntry>•</MarqueeEntry>
      <MarqueeEntry>{t(MARQUEE_ASK_QUESTION)}</MarqueeEntry>
      <MarqueeEntry>•</MarqueeEntry>
      <MarqueeEntry>{t(MARQUEE_WEDDING)}</MarqueeEntry>
      <MarqueeEntry>•</MarqueeEntry>
      <MarqueeEntry>{t(MARQUEE_THANK_YOU)}</MarqueeEntry>
    </>
  );
  const HorizontalMarquee = (
    <OccasionsMarqueeContainer>
      <FastMarquee
        style={{ direction: 'ltr', height: '40px', alignItems: 'center' }}
        gradient={false}
        speed={50}
        direction={locale == 'en' ? 'left' : 'right'}
      >
        {MarqueeEntries}
        {MarqueeEntries}
        {MarqueeEntries}
      </FastMarquee>
    </OccasionsMarqueeContainer>
  );

  return (
    <Container id='celebrities'>
      <Gray2Background />
      <YellowBackground />
      {HorizontalMarquee}
      <CelebritySectionTitle>
        {t(CELEBRITY_TITLE_1)}
        {` `} <BlackText>{t(CELEBRITY_TITLE_BLACK_1)}</BlackText> {` `}
        {t(CELEBRITY_TITLE_2)}
        {` `} <BlackText>{t(CELEBRITY_TITLE_BLACK_2)}</BlackText> {` `}
        {t(CELEBRITY_TITLE_3)}
      </CelebritySectionTitle>
      <CelebrityGrid>
        {allGridItems.map((item, index) => {
          return (
            <CelebrityCard
              key={item.slug}
              grid='main'
              featured={index < featuredCount}
              hiddenOnMobile={mobileFeaturedCount <= index && index < featuredCount}
            >
              <ProfileCard key={item.slug} {...item} />
            </CelebrityCard>
          );
        })}
      </CelebrityGrid>
    </Container>
  );
};

export default CelebritySection;
