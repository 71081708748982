import React, { useEffect, useState } from 'react';
import { ArrowIcon, Background } from './ScrollTopButton.styles';
import { ScrollTopButtonProps } from './ScrollTopButton.types';

const ScrollTopButton = ({ onClick }: ScrollTopButtonProps): JSX.Element => {
  const [scrollDistance, setScrollDistance] = useState<number>(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollDistance(window.pageYOffset);
    };

    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', handleScroll);

      handleScroll();

      return () => window.removeEventListener('scroll', handleScroll);
    }
    handleScroll();
  }, []);

  return (
    <Background onClick={onClick} scrollDistance={scrollDistance}>
      <ArrowIcon />
    </Background>
  );
};

export default ScrollTopButton;
