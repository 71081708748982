import SentEmail from '@assets/sent_email';
import { Body1, Heading5 } from '@components/Texts/Texts';
import tw, { styled } from 'twin.macro';

export const CloseIcon = styled.span`
  ${tw`cursor-pointer absolute top-3 end-3 p-2 rounded-full`}
  background: #303036;
  border: 1px solid rgba(255, 255, 255, 0.1);
  z-index: 51;
`;

export const TopSection = styled.div`
  ${tw`relative flex justify-center pt-3 pb-6`}
`;

export const LightBg = styled.div`
  ${tw`absolute -inset-x-3 bottom-0 -top-3 rounded-t-lg`}
  background: rgba(255, 255, 255, 0.1);
`;

export const SentEmailIcon = styled(SentEmail)`
  z-index: 51;
`;

export const BottomSection = styled.div`
  ${tw`py-2`}
`;

export const Header = styled(Heading5)`
  ${tw`text-light-high my-2`}
`;

export const Text = styled(Body1)`
  ${tw`text-light-low`}
`;

export const BoldText = styled.span`
  ${tw`font-semibold text-light-high`}
`;
