import Play from '@assets/play';
import { getImageSrc } from '@common/image-helper';
import { Body1 } from '@components/Texts/Texts';
import ScrollContainer from 'react-indiana-drag-scroll';
import tw, { styled, theme } from 'twin.macro';

export const Container = styled.section`
  ${tw`col-span-full relative`}
  ${tw`mt-8 mb-12`}
  ${tw`sm:mt-3 sm:mb-6`}
`;

export const VideosTitle = styled.p`
  ${tw`text-light-medium text-headline-4 font-bold mb-4`}
  ${tw`sm:text-headline-5 sm:font-semibold mb-3`}
`;

export const VideosScroll = styled(ScrollContainer)`
  ${tw`flex flex-row overflow-hidden`}
`;

export const VideoItemContainer = styled.div<{ photo: string }>`
  height: 464px;
  min-width: 280px;
  border-radius: 16px;
  background: no-repeat top/cover;
  background-image: linear-gradient(180deg, rgba(32, 32, 37, 0) 0%, rgba(32, 32, 37, 0.75) 100%),
    url(${props => getImageSrc({ imageSrc: props.photo, height: 928, focus: 'face' })});
  ${tw`relative cursor-pointer me-3`};
  @media (min-width: ${theme`screens.sm.min`}) and (max-width: 400px) {
    height: 360px;
    min-width: 216px;
    ${tw`me-2`};
  }
`;

export const VideoItemDetails = styled.div`
  ${tw`absolute bottom-2 mx-2`};
`;

export const PlayContainer = styled.div<{ photo: string }>`
  background: ${p => `linear-gradient(0deg, rgba(7, 7, 8, 0.25), rgba(7, 7, 8, 0.25)), url(${p.photo}) `};
  background-size: cover;
  background-position: center;
  ${tw`w-8 h-8 mb-1 flex items-center justify-center border-button-primary border-solid border-2 rounded-full`}
`;

export const PlayIcon = styled(Play)`
  width: 20px;
  height: 20px;
`;

export const FullName = styled(Body1)`
  ${tw`text-light-high font-semibold`}
`;
