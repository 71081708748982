import tw, { css, styled, theme } from 'twin.macro';

export const Container = styled.section`
  ${tw`relative grid col-span-full`}
  ${tw`sm:pb-6`}
  ${tw`md:pb-6`}
  ${tw`lg:grid-cols-12 lg:gap-x-3 lg:pb-8`}
  ${tw`xl:grid-cols-12 xl:gap-x-3 xl:pb-8`}
`;

export const CelebritySectionTitle = styled.h2`
  ${tw`text-dark-medium font-bold`}
  ${tw`sm:text-headline-4 sm:text-center sm:pb-6 sm:pt-15`}
  ${tw`md:text-headline-4 md:text-center md:pb-6 md:pt-15`}
  ${tw`lg:text-headline-3 lg:col-start-2 lg:col-end-7 lg:pb-12 lg:pt-16`}
  ${tw`xl:text-headline-3 xl:col-start-2 xl:col-end-7 xl:pb-12 xl:pt-16`}
  z-index: 2;
  [dir='rtl'] & {
    line-height: 120%;
  }
`;

export const BlackText = styled.span`
  ${tw`text-dark-high`}
`;

export const YellowBackground = styled.div`
  ${tw`absolute bg-brand-primary`}
  ${tw`sm:-left-2 sm:-right-2`}
  ${tw`md:-left-3 md:-right-3`}
  ${tw`lg:-left-8 lg:-right-8`}
  ${tw`xl:-left-8 xl:-right-8`}
  height: 560px;
  ${css`
    @media (max-width: ${theme`screens.md.max`}) {
      height: 400px;
    }
  `}
`;

export const CelebrityGrid = styled.div`
  ${tw`flex-1 grid col-span-full gap-y-3`}
  ${tw`sm:grid-cols-4 sm:gap-x-2`}
  ${tw`md:grid-cols-8 md:gap-x-2`}
  ${tw`lg:grid-cols-12 lg:gap-x-3`}
  ${tw`xl:grid-cols-12 xl:gap-x-3`}
`;

export const CelebrityCard = styled.div<{ grid: 'main' | 'featured'; featured: boolean; hiddenOnMobile: boolean }>`
  ${tw`sm:col-span-2`}
  ${tw`md:col-span-4`}
  ${tw`lg:col-span-3`}
  ${tw`xl:col-span-3`}
  ${props =>
    props.grid === 'main' &&
    css`
      @media (max-width: ${theme`screens.sm.max`}) {
        display: ${props.featured && !props.hiddenOnMobile ? 'none' : ''};
      }
      @media (min-width: ${theme`screens.md.min`}) {
        display: ${props.featured ? 'none' : ''};
      }
    `}
  ${props =>
    props.grid === 'featured' &&
    css`
      @media (max-width: ${theme`screens.sm.max`}) {
        display: ${props.featured && props.hiddenOnMobile ? 'none' : ''};
      }
    `}
`;

export const Gray2Background = styled.div`
  z-index: -1;
  ${tw`absolute inset-y-0`}
  ${tw`sm:-left-2 sm:-right-2`}
  ${tw`md:-left-3 md:-right-3`}
  ${tw`lg:-left-8 lg:-right-8`}
  ${tw`xl:-left-8 xl:-right-8`}
  background-color: rgb(24, 24, 27);
`;

export const OccasionsMarqueeContainer = styled.div`
  ${tw`absolute top-6`}
  ${tw`sm:-left-2 sm:-right-2`}
  ${tw`md:-left-3 md:-right-3`}
  ${tw`lg:-left-8 lg:-right-8`}
  ${tw`xl:-left-8 xl:-right-8`}
  background-color: rgba(24, 24, 27, 1);
`;

export const MarqueeEntry = styled.div`
  ${tw`text-light-high mx-1 w-max`}
`;
