import tw, { styled } from 'twin.macro';

const imageUrl = 'https://ik.imagekit.io/yela/Production/About_Us/tr:h-350,q-80/NEWEST_-_collage_wOqMAVz2M.png';

export const Container = styled.div`
  height: 350px;
  background: no-repeat center/cover;
  background-image: url(${imageUrl});
  ${tw`mb-12`};
  ${tw`sm:col-span-4 sm:mb-3`};
  ${tw`md:col-span-8 md:mb-3`};
  ${tw`lg:col-span-12`};
  ${tw`xl:col-span-12`};
`;

export const Shading = styled.div`
  background: rgba(0, 0, 0, 0.84);
  ${tw`h-full w-full items-center text-light-high grid`};
  ${tw`sm:grid-cols-4 sm:gap-x-2`}
  ${tw`md:grid-cols-8 md:gap-x-2`}
  ${tw`lg:grid-cols-12 lg:gap-x-3`}
  ${tw`xl:grid-cols-12 xl:gap-x-3`}
`;

export const HeadlineText = styled.div`
  ${tw`text-center`};
  ${tw`sm:col-span-4 sm:px-2`}
  ${tw`md:col-span-8`}
  ${tw`lg:col-start-4 lg:col-end-10`};
  ${tw`xl:col-start-4 xl:col-end-10`};
`;
