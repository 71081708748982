import Close from '@assets/close';
import { LoaderOptions } from '@components/Button/LoaderOptions';
import Routes from '@constants/routes';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import { MutableRefObject, useEffect, useRef, useState } from 'react';
import Lottie from 'react-lottie';
import { I18N_COMMON_CONTEXT, SITE_VIDEO_CTA_BUTTON_TEXT } from './SiteVideoPlayer.constants';
import {
  BackVideoSmallIcon,
  CloseButton,
  Container,
  CTAButton,
  FullName,
  Gradients,
  Header,
  Heading,
  SecondaryActionPlaceholder,
  SiteVideoPlayContainer,
  SiteVideoPlayIcon,
  Subtext,
  VideoDetails,
  VideoDetailsBottom,
  VideoHeader,
  VideoHeaderText,
  VideoPlayer,
  VideoPlayerContainer,
  VideoPlayerContainerInner,
  YelaLogo,
} from './SiteVideoPlayer.styles';
import { SiteVideoPlayerInterface } from './SiteVideoPlayer.types';

const SiteVideoPlayer = ({ videoItem, show, close }: SiteVideoPlayerInterface): JSX.Element => {
  const { t } = useTranslation(I18N_COMMON_CONTEXT);
  const router = useRouter();
  const videoRef = useRef() as MutableRefObject<
    HTMLVideoElement & {
      webkitEnterFullscreen(): () => void;
    }
  >;
  const [isVideoPlaying, setIsVideoPlaying] = useState<boolean>(false);
  const [isCTALoading, setIsCTALoading] = useState<boolean>(false);
  const [isVideoLoading, setIsVideoLoading] = useState<boolean>(false);

  const onVideoLoaded = () => {
    setIsVideoLoading(false);
  };

  const pauseVideo = () => {
    videoRef.current.pause();
    setIsVideoPlaying(false);
  };

  const playVideo = () => {
    videoRef.current.play().catch(e => console.log(e));
    setIsVideoPlaying(true);
  };

  const closeVideo = () => {
    if (!videoRef.current) return;
    pauseVideo();
    videoRef.current.currentTime = 0;
    close();
  };

  const replayVideo = () => {
    videoRef.current.currentTime = 0;
    playVideo();
  };

  const goToProfile = (slug: string) => {
    setIsCTALoading(true);
    router.push(Routes.PROFILE(slug));
  };

  useEffect(() => {
    const bodyElement = document.getElementsByTagName('body')[0];
    if (show) {
      if (videoRef.current.readyState == 0) setIsVideoLoading(true);
      videoRef.current?.play().catch(e => console.log(e));
      setIsVideoPlaying(true);
      bodyElement.style.overflow = 'hidden';
    } else {
      bodyElement.style.overflow = 'auto';
    }

    return () => {
      if (show) bodyElement.style.overflow = 'auto';
    };
  }, [show]);

  return (
    <Container show={show}>
      <Header>
        <YelaLogo />
        <CloseButton onClick={closeVideo}>
          <Close />
        </CloseButton>
      </Header>
      <VideoPlayerContainer>
        <VideoPlayerContainerInner>
          {isVideoLoading && <Lottie options={LoaderOptions} height={64} width={64} style={{ pointerEvents: 'none' }} />}
          <VideoPlayer src={videoItem?.video} ref={videoRef} onLoadedData={onVideoLoaded} onEnded={replayVideo} playsInline />
          {videoItem && !isVideoLoading && (
            <>
              <Gradients />
              <VideoDetails onClick={pauseVideo}>
                <VideoHeader>
                  <BackVideoSmallIcon onClick={closeVideo} />
                  <VideoHeaderText>
                    <Heading>{videoItem.heading}</Heading>
                    <Subtext>{videoItem.subtext}</Subtext>
                  </VideoHeaderText>
                  <SecondaryActionPlaceholder />
                </VideoHeader>
              </VideoDetails>
              <SiteVideoPlayContainer onClick={playVideo} show={!isVideoPlaying}>
                <SiteVideoPlayIcon />
              </SiteVideoPlayContainer>
              <VideoDetailsBottom>
                <FullName>{videoItem.fullname}</FullName>
                <CTAButton
                  text={t(SITE_VIDEO_CTA_BUTTON_TEXT)}
                  loadingState={isCTALoading}
                  onClick={() => {
                    goToProfile(videoItem.slug);
                  }}
                />
              </VideoDetailsBottom>
            </>
          )}
        </VideoPlayerContainerInner>
      </VideoPlayerContainer>
    </Container>
  );
};
export default SiteVideoPlayer;
