export const I18N_HOME_CONTEXT = 'home';

export const CELEBRITY_TITLE_1 = 'celebrity-section-title-1';
export const CELEBRITY_TITLE_BLACK_1 = 'celebrity-section-title-black-1';
export const CELEBRITY_TITLE_2 = 'celebrity-section-title-2';
export const CELEBRITY_TITLE_BLACK_2 = 'celebrity-section-title-black-2';
export const CELEBRITY_TITLE_3 = 'celebrity-section-title-3';

export const MARQUEE_BIRTHDAY = 'marquee-birthday';
export const MARQUEE_THANK_YOU = 'marquee-thank-you';
export const MARQUEE_GET_WELL_SOON = 'marquee-get-well-soon';
export const MARQUEE_ASK_QUESTION = 'marquee-ask-question';
export const MARQUEE_WEDDING = 'marquee-wedding';
export const MARQUEE_WEDDING_INVITATION = 'marquee-wedding-invitation';
