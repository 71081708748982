import * as MixpanelHomePageActions from '@analytics/mixpanel/actions/HomePage';
import { SeoLink } from '@common/SeoLink';
import { formatPrice } from '@common/utils';
import { Body2, Heading5 } from '@components/Texts/Texts';
import Routes from '@constants/routes';
import { useYela } from '@hooks/useYela';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import { COMING_SOON, GOING_FAST, I18N_HOME_CONTEXT, LET_YOU_KNOW_MESSAGE, SOLD_OUT, STARTING_AT } from './ProfileCard.constants';
import { Badge, BadgeContainer, BadgeText, Container, Details, HoverShade, Name, PriceTag, SoldOutShade } from './ProfileCard.styles';
import { ProfileCardProps } from './ProfileCard.types';

const ProfileCard = ({
  photo,
  fullname,
  videoPricing,
  audioPricing,
  slug,
  goingFast,
  available,
  videoLimiting,
  audioLimiting,
  featured,
}: ProfileCardProps): JSX.Element => {
  const router = useRouter();
  const { language } = useYela();
  const { t } = useTranslation(I18N_HOME_CONTEXT);
  const bothSoldOut = videoLimiting.soldOut && audioLimiting.soldOut;
  const displayPricing = !bothSoldOut && available;
  let priceTag = '';
  const badgeLookup: any = {
    GoingFast: { text: t(GOING_FAST), bgColor: 'white' },
    SoldOut: { text: t(SOLD_OUT), bgColor: 'neutral' },
    ComingSoon: { text: t(COMING_SOON), bgColor: 'neutral' },
  };

  let badgeEntry = undefined;
  if (!available) {
    badgeEntry = badgeLookup['SoldOut'];
  } else {
    if (videoLimiting.soldOut && audioLimiting.soldOut) {
      badgeEntry = badgeLookup['ComingSoon'];
    } else if (goingFast) {
      badgeEntry = badgeLookup['GoingFast'];
    }
  }

  if (displayPricing) {
    if (audioLimiting.soldOut) {
      priceTag = formatPrice({
        currencyCode: videoPricing.currencyCode,
        currencySymbol: videoPricing.currencySymbol,
        value: videoPricing.price,
        flip: language == 'ar',
      });
    } else {
      priceTag = formatPrice({
        currencyCode: videoPricing.currencyCode,
        currencySymbol: audioPricing.currencySymbol,
        value: audioPricing.price,
        flip: language == 'ar',
      });
    }
  }

  return (
    <Container
      photo={photo}
      available={available}
      onClick={() => {
        MixpanelHomePageActions.onCelebrityItemClicked({
          router,
          celebrityDetails: {
            celebrityName: slug,
            goingFast,
            soldOut: !available,
            comingSoon: available && videoLimiting.soldOut && audioLimiting.soldOut,
            featured: !!featured,
          },
        });
        if (!available) return;
        router.push(Routes.PROFILE(slug));
      }}
    >
      <SeoLink href={Routes.PROFILE(slug)} />
      {available ? <HoverShade /> : <SoldOutShade />}
      <Details>
        {badgeEntry && (
          <BadgeContainer bgColor={badgeEntry.bgColor}>
            <Badge bgColor={badgeEntry.bgColor}>
              <Body2>
                <BadgeText>{badgeEntry.text}</BadgeText>
              </Body2>
            </Badge>
          </BadgeContainer>
        )}
        <Heading5>
          <Name>{fullname}</Name>
        </Heading5>
        <Body2>
          {displayPricing ? (
            <>
              {t(STARTING_AT) + ' '}
              <PriceTag>{priceTag}</PriceTag>
            </>
          ) : available ? (
            <>{t(LET_YOU_KNOW_MESSAGE)}</>
          ) : (
            <></>
          )}
        </Body2>
      </Details>
    </Container>
  );
};

export default ProfileCard;
