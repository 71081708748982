import * as MixpanelHomePageActions from '@analytics/mixpanel/actions/HomePage';
import { getImageSrc } from '@common/image-helper';
import { VideoItem } from '@features/common/SiteVideoPlayer/SiteVideoPlayer.types';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import { I18N_HOME_CONTEXT, VIDEOS_TITLE } from './VideosSection.constants';
import {
  Container,
  FullName,
  PlayContainer,
  PlayIcon,
  VideoItemContainer,
  VideoItemDetails,
  VideosScroll,
  VideosTitle,
} from './VideosSection.styles';
import { VideosSectionProps } from './VideosSection.types';

const VideosSection = ({ videoItemsInitial, setSelectedVideoItem, setIsShowSiteVideoPlayer }: VideosSectionProps): JSX.Element => {
  const router = useRouter();
  const { t } = useTranslation(I18N_HOME_CONTEXT);

  const openVideo = async (videoItem: VideoItem) => {
    MixpanelHomePageActions.onHomepageVideoPlayed({ router, slug: videoItem?.slug });
    await setSelectedVideoItem(videoItem);
    setIsShowSiteVideoPlayer(true);
  };

  return (
    <Container>
      <VideosTitle>{t(VIDEOS_TITLE)}</VideosTitle>
      <VideosScroll>
        {videoItemsInitial.map((videoItem, index) => {
          return (
            <VideoItemContainer
              key={index}
              photo={videoItem.thumbnailPhoto}
              onClick={() => {
                openVideo(videoItem);
              }}
            >
              <VideoItemDetails>
                <PlayContainer photo={getImageSrc({ imageSrc: videoItem.photo, height: 64, width: 64, focus: 'face' })}>
                  <PlayIcon />
                </PlayContainer>
                <FullName>{videoItem.fullname}</FullName>
              </VideoItemDetails>
            </VideoItemContainer>
          );
        })}
      </VideosScroll>
    </Container>
  );
};

export default VideosSection;
