import BackVideoSmall from '@assets/back_video_small';
import Logo from '@assets/logo';
import SiteVideoPlay from '@assets/site_video_play';
import Button from '@components/Button/Button';
import { Heading6, Subtitle2 } from '@components/Texts/Texts';
import tw, { css, styled, theme } from 'twin.macro';

export const Container = styled.div<{ show: boolean }>`
  background: rgba(24, 24, 27, 1);
  transition: opacity 0.15s linear;
  ${tw`fixed inset-0`}
  ${p => (p.show ? tw`opacity-100` : tw`opacity-0 pointer-events-none`)};
  z-index: ${p => (p.show ? 100 : -1)};
  ${tw`md:py-4 md:px-8`}
  ${tw`lg:py-4 lg:px-8`}
  ${tw`xl:py-4 xl:px-8`}
`;

export const Header = styled.div`
  ${tw`flex flex-row justify-between items-center`}
  ${tw`sm:hidden`}
`;

export const YelaLogo = styled(Logo)`
  ${tw`cursor-pointer z-10`}
`;

export const CloseButton = styled.div`
  background: rgba(48, 48, 54, 1);
  border: 1px solid rgba(255, 255, 255, 0.05);
  ${tw`cursor-pointer h-6 w-6 flex items-center justify-center rounded-full z-10`}
`;

export const VideoPlayerContainer = styled.div`
  height: calc(100% - 64px);
  width: calc(100% - 128px);
  ${tw`absolute top-4`}
  ${tw`flex flex-row justify-center`}
  ${tw`sm:h-full sm:w-full sm:block sm:top-0`}
  ${css`
    @media (min-width: ${theme`screens.md.min`}) and (max-width: 1025px) {
      ${tw`top-11`}
      height: calc(100% - 120px);
    }
  `}
`;

export const VideoPlayerContainerInner = styled.div`
  ${tw`relative sm:h-full sm:w-full`}
`;

export const VideoPlayer = styled.video`
  ${tw`h-full w-full object-cover`}
  border-radius: 8px;
  ${tw`sm:rounded-none`}
`;

export const Gradients = styled.div`
  background: linear-gradient(180deg, rgba(7, 7, 8, 0.75) 0%, rgba(7, 7, 8, 0) 13.72%),
    linear-gradient(180deg, rgba(7, 7, 8, 0) 58.54%, rgba(7, 7, 8, 0.75) 100%);
  width: calc(100% + 1px);
  border-radius: 8px;
  ${tw`h-full absolute top-0 start-0 pointer-events-none`}
  ${tw`sm:rounded-none`}
`;

export const VideoDetails = styled.div`
  ${tw`h-full w-full absolute top-0 start-0 p-2 cursor-pointer`}
`;

export const VideoHeader = styled.div`
  ${tw`flex flex-row justify-between items-center`}
`;

export const BackVideoSmallIcon = styled(BackVideoSmall)`
  ${tw`w-5 h-5 cursor-pointer hidden z-10`};
  ${tw`sm:block`}
  [dir='rtl'] & {
    transform: scaleX(-1);
  }
`;

export const VideoHeaderText = styled.div`
  ${tw`sm:text-center`}
`;

export const Heading = styled(Heading6)`
  ${tw`text-light-high`}
`;

export const Subtext = styled(Subtitle2)`
  ${tw`text-light-medium font-semibold mt-0.5`}
`;

export const SecondaryActionPlaceholder = styled.div`
  ${tw`w-5 h-5 pointer-events-none`};
`;

export const SiteVideoPlayContainer = styled.div<{ show: boolean }>`
  ${tw`flex flex-row justify-center items-center absolute top-0 start-0 w-full h-full cursor-pointer`};
  ${p => !p.show && tw`hidden`};
`;

export const SiteVideoPlayIcon = styled(SiteVideoPlay)`
  ${tw`w-10 h-10`};
`;

export const VideoDetailsBottom = styled.div`
  ${tw`w-full absolute bottom-0 start-0 pb-4 px-2`}
`;

export const FullName = styled(Heading6)`
  ${tw`text-light-high`}
`;

export const CTAButton = styled(Button)`
  padding-top: 12px;
  padding-bottom: 12px;
  ${tw`mt-3 uppercase h-5 px-2`};
`;
