import Close from '@assets/close';
import Modal from '@components/Modal/Modal';
import useTranslation from 'next-translate/useTranslation';
import { EMAIL_OPT_IN_MODAL_TEXT, EMAIL_OPT_IN_MODAL_TITLE, I18N_HOME_CONTEXT } from './EmailOptInModal.constants';
import { BottomSection, CloseIcon, Header, LightBg, SentEmailIcon, Text, TopSection } from './EmailOptInModal.styles';
import { EmailOptInModalProps } from './EmailOptInModal.types';

const EmailReminderModal = ({ toDisplay, closePopup }: EmailOptInModalProps): JSX.Element => {
  const { t } = useTranslation(I18N_HOME_CONTEXT);

  return (
    <Modal width={422} toDisplay={toDisplay}>
      <CloseIcon onClick={() => closePopup()}>
        <Close />
      </CloseIcon>
      <TopSection>
        <LightBg />
        <SentEmailIcon />
      </TopSection>
      <BottomSection>
        <Header>{t(EMAIL_OPT_IN_MODAL_TITLE)}</Header>
        <Text>{t(EMAIL_OPT_IN_MODAL_TEXT)}</Text>
      </BottomSection>
    </Modal>
  );
};

export default EmailReminderModal;
