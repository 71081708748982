import * as MixpanelHomePageActions from '@analytics/mixpanel/actions/HomePage';
import { I18N_HOME_CONTEXT } from '@layouts/Home/Home.constants';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import { TALENT_BUTTON_TEXT_ONE, TALENT_BUTTON_TEXT_TWO } from './TalentButton.constants';
import {
  TalentButtonArrow,
  TalentButtonContainer,
  TalentButtonTextContainer,
  TalentButtonTextOne,
  TalentButtonTextTwo,
} from './TalentButton.styles';

const TalentButton = (): JSX.Element => {
  const { t } = useTranslation(I18N_HOME_CONTEXT);
  const router = useRouter();

  const onButtonClick = () => {
    MixpanelHomePageActions.onEnrollTalentButtonClicked({ router });
  };

  return (
    <TalentButtonContainer href='https://www.instagram.com/weareyela/' target='_blank' rel='​noopener noreferrer' onClick={onButtonClick}>
      <TalentButtonTextContainer>
        <TalentButtonTextOne>{t(TALENT_BUTTON_TEXT_ONE)}</TalentButtonTextOne>
        <TalentButtonTextTwo>{t(TALENT_BUTTON_TEXT_TWO)}</TalentButtonTextTwo>
      </TalentButtonTextContainer>
      <TalentButtonArrow />
    </TalentButtonContainer>
  );
};

export default TalentButton;
