import { SVGProps } from 'react';

export default function BackVideoSmall(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg {...props} width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect width='40' height='40' rx='20' fill='#202025' />
      <path
        d='M14.8242 20.0031C14.824 19.8209 14.8626 19.6407 14.9376 19.4746C15.0125 19.3085 15.122 19.1603 15.2588 19.0399L21.4716 13.5741C21.6775 13.4014 21.9428 13.316 22.2108 13.3363C22.4788 13.3566 22.7282 13.481 22.9057 13.6828C23.0832 13.8846 23.1747 14.1478 23.1606 14.4162C23.1466 14.6846 23.028 14.9368 22.8304 15.119L17.3975 19.8984C17.3826 19.9115 17.3706 19.9276 17.3625 19.9456C17.3543 19.9637 17.35 19.9833 17.35 20.0031C17.35 20.023 17.3543 20.0426 17.3625 20.0606C17.3706 20.0787 17.3826 20.0948 17.3975 20.1079L22.8304 24.8873C22.9355 24.9755 23.0219 25.0837 23.0845 25.2057C23.1471 25.3278 23.1847 25.4611 23.195 25.5978C23.2054 25.7346 23.1883 25.872 23.1448 26.002C23.1013 26.1321 23.0322 26.2521 22.9416 26.3551C22.8511 26.4581 22.7408 26.5419 22.6174 26.6017C22.4939 26.6615 22.3598 26.6959 22.2229 26.7031C22.0859 26.7103 21.9489 26.69 21.8199 26.6435C21.6909 26.597 21.5725 26.5251 21.4716 26.4322L15.261 20.9681C15.1239 20.8474 15.014 20.699 14.9387 20.5326C14.8634 20.3662 14.8244 20.1858 14.8242 20.0031Z'
        fill='white'
      />
    </svg>
  );
}
