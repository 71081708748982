import { getImageSrc } from '@common/image-helper';
import tw, { css, styled, theme } from 'twin.macro';

export const styleMap: any = {
  white: tw`bg-white`,
  neutral: tw`bg-brand-secondary`,
  default: tw`bg-white`,
};

export const Container = styled.div<{ photo: string; available: boolean }>`
  height: 464px;
  background: no-repeat top/cover;
  background-image: linear-gradient(180deg, rgba(32, 32, 37, 0) 0%, rgba(32, 32, 37, 0.75) 100%),
    url(${props => getImageSrc({ imageSrc: props.photo, height: 928, focus: 'face' })});
  ${props => (props.available ? tw`relative rounded cursor-pointer` : tw`relative rounded`)};
  @media (min-width: ${theme`screens.sm.min`}) and (max-width: 400px) {
    height: 296px;
  }
`;

export const BadgeContainer = styled.div<{ bgColor: string }>`
  ${tw`mb-2`};
  ${props => (props.bgColor == 'neutral' ? tw`text-light-high` : tw`text-dark-high`)};
  @media (min-width: ${theme`screens.sm.min`}) and (max-width: 400px) {
    ${tw`mb-1`};
  }
`;

export const Badge = styled.div<{ bgColor: string }>`
  border-radius: 8px;
  ${tw`inline-block p-1`};
  ${props => styleMap[props.bgColor] || styleMap['default']};
`;

export const BadgeText = styled.span`
  ${tw`font-semibold`};
`;

export const Details = styled.div`
  ${tw`absolute p-2 bottom-0 w-full text-light-high text-center`};
`;

export const Name = styled.div`
  ${tw`mb-1`};
  ${css`
    @media (min-width: ${theme`screens.sm.min`}) and (max-width: 400px) {
      ${tw`text-body-1 mb-0`};
    }
  `}
`;

export const PriceTag = styled.span`
  ${tw`font-semibold`};
`;

export const SoldOutShade = styled.div`
  background: rgb(7, 7, 8);
  ${tw`h-full w-full opacity-75 rounded`};
`;

export const HoverShade = styled.div`
  ${tw`h-full w-full opacity-0 rounded`}
  background: rgba(7, 7, 8, 0.35);
  transition: opacity 250ms ease-in-out;
  ${Container}:hover & {
    ${tw`opacity-100`}
  }
`;
