import Arrow from '@assets/arrow';
import tw, { styled } from 'twin.macro';

type BackgroundProps = {
  scrollDistance: number;
};

export const Background = styled.button<BackgroundProps>`
  ${tw`sm:flex`}
  transition: opacity 250ms ease-in-out;
  ${p => (p.scrollDistance > 400 ? tw`opacity-100` : tw`opacity-0`)}
  ${tw`fixed right-2 bottom-2 h-7 w-7 hidden items-center justify-center bg-white rounded-full border-1 border-solid border-dark-disabled z-10`}
  &:focus {
    outline: none;
  }
  @supports selector(:focus-visible) {
    &:focus-visible {
      outline: 1px solid black;
    }
  }
`;

export const ArrowIcon = styled(Arrow)`
  ${tw`opacity-70`}
`;
