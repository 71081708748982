import tw, { css, styled, theme } from 'twin.macro';

export const Container = styled.section`
  ${tw`sm:mb-6`}
  ${tw`md:mb-6`}
  ${tw`lg:mb-12`};
  ${tw`xl:mb-12`};
  ${tw`col-span-full`}
`;

export const FeaturedTitle = styled.p`
  ${tw`text-light-medium text-headline-4 font-bold mb-4`}
  ${tw`sm:text-headline-5 sm:font-semibold mb-3`}
`;

export const CelebrityGrid = styled.div`
  ${tw`flex-1 grid col-span-full gap-y-3`}
  ${tw`sm:grid-cols-4 sm:gap-x-2`}
  ${tw`md:grid-cols-8 md:gap-x-2`}
  ${tw`lg:grid-cols-12 lg:gap-x-3`}
  ${tw`xl:grid-cols-12 xl:gap-x-3`}
`;

export const CelebrityCard = styled.div<{ grid: 'main' | 'featured'; featured: boolean; hiddenOnMobile: boolean }>`
  ${tw`sm:col-span-2`}
  ${tw`md:col-span-4`}
  ${tw`lg:col-span-3`}
  ${tw`xl:col-span-3`}
  ${props =>
    props.grid === 'main' &&
    css`
      @media (max-width: ${theme`screens.sm.max`}) {
        display: ${props.featured && !props.hiddenOnMobile ? 'none' : ''};
      }
      @media (min-width: ${theme`screens.md.min`}) {
        display: ${props.featured ? 'none' : ''};
      }
    `}
  ${props =>
    props.grid === 'featured' &&
    css`
      @media (max-width: ${theme`screens.sm.max`}) {
        display: ${props.featured && props.hiddenOnMobile ? 'none' : ''};
      }
    `}
`;
