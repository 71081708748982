import { SVGProps } from 'react';

export default function Close(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg {...props} width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M9.27623 8.0989C9.26329 8.086 9.25303 8.07067 9.24603 8.0538C9.23903 8.03693 9.23542 8.01884 9.23542 8.00057C9.23542 7.9823 9.23903 7.96421 9.24603 7.94734C9.25303 7.93047 9.26329 7.91514 9.27623 7.90224L14.4221 2.75689C14.5784 2.60042 14.6661 2.38829 14.6659 2.16716C14.6658 1.94603 14.5778 1.73402 14.4213 1.57777C14.2648 1.42152 14.0527 1.33383 13.8316 1.33398C13.6104 1.33414 13.3984 1.42213 13.2422 1.5786L8.09683 6.72173C8.08393 6.73467 8.0686 6.74493 8.05173 6.75193C8.03486 6.75893 8.01677 6.76254 7.9985 6.76254C7.98023 6.76254 7.96214 6.75893 7.94527 6.75193C7.9284 6.74493 7.91307 6.73467 7.90017 6.72173L2.75482 1.5786C2.67746 1.5012 2.5856 1.43979 2.4845 1.39789C2.3834 1.35598 2.27504 1.3344 2.1656 1.33438C1.94457 1.33433 1.73258 1.42208 1.57626 1.57833C1.41993 1.73458 1.33208 1.94653 1.33203 2.16755C1.33198 2.38858 1.41973 2.60057 1.57598 2.75689L6.72077 7.90224C6.73371 7.91514 6.74397 7.93047 6.75097 7.94734C6.75797 7.96421 6.76158 7.9823 6.76158 8.00057C6.76158 8.01884 6.75797 8.03693 6.75097 8.0538C6.74397 8.07067 6.73371 8.086 6.72077 8.0989L1.57598 13.2448C1.49861 13.3222 1.43725 13.4141 1.39539 13.5152C1.35354 13.6163 1.33201 13.7247 1.33203 13.8341C1.33208 14.0552 1.41993 14.2671 1.57626 14.4234C1.65366 14.5007 1.74555 14.5621 1.84667 14.604C1.94779 14.6458 2.05616 14.6673 2.1656 14.6673C2.38662 14.6673 2.59857 14.5794 2.75482 14.4231L7.90017 9.27719C7.91307 9.26425 7.9284 9.25399 7.94527 9.24699C7.96214 9.23999 7.98023 9.23638 7.9985 9.23638C8.01677 9.23638 8.03486 9.23999 8.05173 9.24699C8.0686 9.25399 8.08393 9.26425 8.09683 9.27719L13.2422 14.4231C13.3984 14.5794 13.6104 14.6673 13.8314 14.6673C14.0524 14.6674 14.2644 14.5796 14.4207 14.4234C14.5771 14.2671 14.6649 14.0552 14.665 13.8341C14.665 13.6131 14.5773 13.4011 14.421 13.2448L9.27623 8.0989Z'
        fill='white'
        fillOpacity='0.6'
      />
    </svg>
  );
}
