import tw, { styled } from 'twin.macro';

export const Container = styled.section`
  ${tw`sm:col-span-full sm:py-6`}
  ${tw`md:col-span-full md:py-12`}
  ${tw`lg:col-start-3 lg:col-end-11 lg:py-12`}
  ${tw`xl:col-start-3 xl:col-end-11 xl:py-12`}
`;

export const FAQSectionTitle = styled.h2`
  ${tw`text-light-medium pb-4`}
  ${tw`sm:text-headline-5 sm:font-medium`}
  ${tw`md:text-headline-5 md:font-medium`}
  ${tw`lg:text-headline-4 lg:font-bold`}
  ${tw`xl:text-headline-4 xl:font-bold`}
`;

export const FAQSectionText = styled.p`
  ${tw`text-light-low mt-4`}
`;

export const FAQSectionLink = styled.a`
  ${tw`text-light-medium underline`}
`;
