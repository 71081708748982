import { SVGProps } from 'react';

const Play = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg {...props} width='33' height='32' viewBox='0 0 33 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M28.8801 14.4536L5.67021 2.84811C5.40635 2.71693 5.11338 2.65523 4.81902 2.66884C4.52466 2.68245 4.23863 2.77092 3.988 2.92589C3.73736 3.08085 3.53039 3.29719 3.38667 3.55444C3.24295 3.81169 3.16723 4.10135 3.16666 4.39603V27.6059C3.16711 27.9006 3.24278 28.1903 3.3865 28.4475C3.53022 28.7048 3.73724 28.9211 3.98794 29.076C4.23865 29.2309 4.52474 29.3193 4.81913 29.3327C5.11352 29.3461 5.40646 29.2841 5.67021 29.1527L28.8801 17.5483C29.1674 17.4046 29.409 17.1837 29.5779 16.9104C29.7467 16.6371 29.8362 16.3222 29.8362 16.0009C29.8362 15.6797 29.7467 15.3648 29.5779 15.0915C29.409 14.8182 29.1674 14.5973 28.8801 14.4536Z'
        fill='#FFDB01'
      />
    </svg>
  );
};

export default Play;
