import useTranslation from 'next-translate/useTranslation';
import { I18N_HOME_CONTEXT, INFO_1, INFO_2, INFO_3 } from './InformationSection.constants';
import { Container, GrayBackground, GreenCheckIcon, InformationText, InformationTextContainer } from './InformationSection.styles';

const InformationSection = (): JSX.Element => {
  const { t } = useTranslation(I18N_HOME_CONTEXT);

  return (
    <Container>
      <GrayBackground />
      <InformationTextContainer>
        <InformationText>
          <GreenCheckIcon />
          {t(INFO_1)}
        </InformationText>
        <InformationText>
          <GreenCheckIcon />
          {t(INFO_2)}
        </InformationText>
        <InformationText>
          <GreenCheckIcon />
          {t(INFO_3)}
        </InformationText>
      </InformationTextContainer>
    </Container>
  );
};

export default InformationSection;
