import * as MixpanelHomePageActions from '@analytics/mixpanel/actions/HomePage';
import Accordion from '@features/home/Accordion/Accordion';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import {
  FAQ_1_TEXT,
  FAQ_1_TITLE,
  FAQ_2_TEXT,
  FAQ_2_TITLE,
  FAQ_3_TEXT,
  FAQ_3_TITLE,
  FAQ_4_TEXT,
  FAQ_4_TITLE,
  FAQ_5_TEXT,
  FAQ_5_TITLE,
  FAQ_6_TEXT,
  FAQ_6_TITLE,
  FAQ_7_TEXT,
  FAQ_7_TITLE,
  FAQ_LINK,
  FAQ_TEXT,
  FAQ_TITLE,
  I18N_HOME_CONTEXT,
} from './FAQSection.constants';
import { Container, FAQSectionLink, FAQSectionText, FAQSectionTitle } from './FAQSection.styles';

const FAQSection = (): JSX.Element => {
  const { t } = useTranslation(I18N_HOME_CONTEXT);
  const router = useRouter();

  return (
    <Container>
      <FAQSectionTitle>{t(FAQ_TITLE)}</FAQSectionTitle>
      <Accordion slug={'what_is_yela'} title={t(FAQ_1_TITLE)}>
        {t(FAQ_1_TEXT)}
      </Accordion>
      <Accordion slug={'is_yela_real'} title={t(FAQ_2_TITLE)}>
        {t(FAQ_2_TEXT)}
      </Accordion>
      <Accordion slug={'kinds_of_messages'} title={t(FAQ_3_TITLE)}>
        {t(FAQ_3_TEXT)}
      </Accordion>
      <Accordion slug={'message_expectancy_time'} title={t(FAQ_4_TITLE)}>
        {t(FAQ_4_TEXT)}
      </Accordion>
      <Accordion slug={'adjust_or_cancel_request'} title={t(FAQ_5_TITLE)}>
        {t(FAQ_5_TEXT)}
      </Accordion>
      <Accordion slug={'message_share'} title={t(FAQ_6_TITLE)}>
        {t(FAQ_6_TEXT)}
      </Accordion>
      <Accordion slug={'celebrity_no_response'} title={t(FAQ_7_TITLE)}>
        {t(FAQ_7_TEXT)}
      </Accordion>
      <FAQSectionText>
        {t(FAQ_TEXT)} {` `}
        <FAQSectionLink
          onClick={() => {
            MixpanelHomePageActions.onContactLinkClicked({ router });
          }}
          href='mailto:support@yela.com'
        >
          {t(FAQ_LINK)}
        </FAQSectionLink>
      </FAQSectionText>
    </Container>
  );
};

export default FAQSection;
