import { SVGProps } from 'react';

const Arrow = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg {...props} width='20' height='13' viewBox='0 0 20 13' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M9.99912 1.4102e-06C10.2609 -0.000322619 10.5198 0.0551974 10.7584 0.162856C10.9971 0.270515 11.21 0.427837 11.383 0.624321L19.2358 9.54889C19.3694 9.69377 19.4726 9.86388 19.5395 10.0492C19.6063 10.2346 19.6354 10.4314 19.6251 10.6282C19.6148 10.825 19.5653 11.0177 19.4794 11.195C19.3935 11.3724 19.2731 11.5307 19.1252 11.6609C18.9772 11.791 18.8047 11.8903 18.6179 11.9528C18.431 12.0153 18.2336 12.0398 18.0371 12.0249C17.8406 12.0101 17.6491 11.956 17.4738 11.8661C17.2985 11.7761 17.143 11.652 17.0163 11.5011L10.1496 3.69709C10.1308 3.67567 10.1077 3.6585 10.0817 3.64674C10.0558 3.63497 10.0276 3.62889 9.99912 3.62889C9.97063 3.62889 9.94247 3.63497 9.91652 3.64674C9.89056 3.6585 9.86743 3.67567 9.84865 3.69709L2.98193 11.5027C2.85528 11.6536 2.69973 11.7777 2.52443 11.8677C2.34914 11.9576 2.15762 12.0117 1.96115 12.0265C1.76468 12.0414 1.56721 12.0169 1.38036 11.9544C1.19351 11.8919 1.02104 11.7926 0.873086 11.6625C0.725137 11.5323 0.604692 11.374 0.518833 11.1966C0.432974 11.0193 0.383432 10.8266 0.37312 10.6298C0.362808 10.433 0.391933 10.2362 0.458783 10.0508C0.525634 9.86548 0.628861 9.69537 0.762398 9.55049L8.61281 0.627522C8.78614 0.430553 8.99939 0.272726 9.23842 0.164531C9.47744 0.0563361 9.73675 0.000250551 9.99912 1.4102e-06Z'
        fill='currentColor'
      />
    </svg>
  );
};

export default Arrow;
