import Mixpanel from '@analytics/mixpanel/Mixpanel';
import {
  OnCelebrityItemClickedInput,
  OnFAQToggleInput,
  OnHomepageVideoPlayedInput,
  OnJoinMailingListFailedInput,
  OnPressItemClickedInput,
} from '@analytics/mixpanel/types/HomePage.types';
import { BaseInput } from '@analytics/mixpanel/types/Mixpanel.types';

export const onHomePageLoaded = ({ router }: BaseInput): void => {
  Mixpanel.people.set(`Date of Last Event`, new Date().toUTCString());
  Mixpanel.people.set(`Last Event`, `Home Page Loaded`);
  Mixpanel.track(`Home Page Loaded`, Mixpanel.createEventProperties({ router }));
};

export const onCelebrityItemClicked = ({ router, celebrityDetails }: OnCelebrityItemClickedInput): void => {
  Mixpanel.people.set(`Date of Last Event`, new Date().toUTCString());
  Mixpanel.people.set(`Last Event`, `Celebrity Item Clicked`);
  Mixpanel.track(
    `Celebrity Item Clicked`,
    Mixpanel.createEventProperties({
      router,
      additionalProperties: {
        'Celebrity Name': celebrityDetails.celebrityName,
        'Going Fast': celebrityDetails.goingFast,
        'Sold Out': celebrityDetails.soldOut,
        'Coming Soon': celebrityDetails.comingSoon,
        Featured: celebrityDetails.featured,
      },
    }),
  );
};

export const onFAQItemExpanded = ({ router, slug }: OnFAQToggleInput): void => {
  Mixpanel.people.set(`Date of Last Event`, new Date().toUTCString());
  Mixpanel.people.set(`Last Event`, `FAQ Item Expanded`);
  Mixpanel.track(
    `FAQ Item Expanded`,
    Mixpanel.createEventProperties({
      router,
      additionalProperties: {
        'FAQ Item Slug': slug,
      },
    }),
  );
};

export const onFAQItemCollapsed = ({ router, slug }: OnFAQToggleInput): void => {
  Mixpanel.people.set(`Date of Last Event`, new Date().toUTCString());
  Mixpanel.people.set(`Last Event`, `FAQ Item Collapsed`);
  Mixpanel.track(
    `FAQ Item Collapsed`,
    Mixpanel.createEventProperties({
      router,
      additionalProperties: {
        'FAQ Item Slug': slug,
      },
    }),
  );
};

export const onContactLinkClicked = ({ router }: BaseInput): void => {
  Mixpanel.people.set(`Date of Last Event`, new Date().toUTCString());
  Mixpanel.people.set(`Last Event`, `Contact Support Link Clicked`);
  Mixpanel.track(
    `Contact Support Link Clicked`,
    Mixpanel.createEventProperties({
      router,
    }),
  );
};

export const onPressItemClicked = ({ router, press }: OnPressItemClickedInput): void => {
  Mixpanel.people.set(`Date of Last Event`, new Date().toUTCString());
  Mixpanel.people.set(`Last Event`, `Press Item Clicked`);
  Mixpanel.track(
    `Press Item Clicked`,
    Mixpanel.createEventProperties({
      router,
      additionalProperties: {
        Press: press,
      },
    }),
  );
};

export const onJoinMailingListButtonClicked = ({ router }: BaseInput): void => {
  Mixpanel.people.set(`Date of Last Event`, new Date().toUTCString());
  Mixpanel.people.set(`Last Event`, `Join Mailing List Button Clicked`);
  Mixpanel.track(
    `Join Mailing List Button Clicked`,
    Mixpanel.createEventProperties({
      router,
    }),
  );
};

export const onJoinMailingListSuccess = ({ router }: BaseInput): void => {
  Mixpanel.people.set(`Date of Last Event`, new Date().toUTCString());
  Mixpanel.people.set(`Last Event`, `Join Mailing List Success`);
  Mixpanel.track(
    `Join Mailing List Success`,
    Mixpanel.createEventProperties({
      router,
    }),
  );
};

export const onJoinMailingListFailed = ({ router, failureInfo }: OnJoinMailingListFailedInput): void => {
  Mixpanel.people.set(`Date of Last Event`, new Date().toUTCString());
  Mixpanel.people.set(`Last Event`, `Join Mailing List Failed`);

  const additionalProperties: any = {
    'Failure Type': failureInfo.type,
  };
  if (failureInfo.type == 'Server Error') {
    additionalProperties['Error Code'] = failureInfo.errorCode;
  }

  Mixpanel.track(
    `Join Mailing List Failed`,
    Mixpanel.createEventProperties({
      router,
      additionalProperties,
    }),
  );
};

export const onHeaderEmailOptinLinkClicked = ({ router }: BaseInput): void => {
  Mixpanel.people.set(`Date of Last Event`, new Date().toUTCString());
  Mixpanel.people.set(`Last Event`, `Header Email Optin Link Clicked`);
  Mixpanel.track(
    `Header Email Optin Link Clicked`,
    Mixpanel.createEventProperties({
      router,
    }),
  );
};

export const onHomepageVideoPlayed = ({ router, slug }: OnHomepageVideoPlayedInput): void => {
  Mixpanel.people.set(`Date of Last Event`, new Date().toUTCString());
  Mixpanel.people.set(`Last Event`, `Homepage Video Played`);
  Mixpanel.people.increment(`Homepage Video Played Count`);
  Mixpanel.track(
    `Homepage Video Played`,
    Mixpanel.createEventProperties({
      router,
      additionalProperties: {
        'Celebrity Name': slug,
      },
    }),
  );
};

export const onEnrollTalentButtonClicked = ({ router }: BaseInput): void => {
  Mixpanel.people.set(`Date of Last Event`, new Date().toUTCString());
  Mixpanel.people.set(`Last Event`, `Enroll Talent Button Clicked`);
  Mixpanel.track(
    `Enroll Talent Button Clicked`,
    Mixpanel.createEventProperties({
      router,
    }),
  );
};
