import API from '@api/api-base';
import { CelebrityResponse } from '@api/Celebrities';
import Endpoints from '@constants/endpoints';
import { BaseResponse } from './model/base-response';

export type HomePageResponse = CelebrityResponse[];

export const Get = async (locale?: string): Promise<BaseResponse<HomePageResponse>> => {
  const result = await API.get<HomePageResponse>(Endpoints.HOME_PAGE, locale);
  return result;
};

interface EmailOptInRequest {
  email: string;
}

export const EmailOptIn = async (info: EmailOptInRequest): Promise<BaseResponse<any>> => {
  const result = await API.post<EmailOptInRequest, any>(Endpoints.EMAIL_OPT_IN, info);
  return result;
};
