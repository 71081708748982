export const I18N_HOME_CONTEXT = 'home';

export const FAQ_TITLE = 'faq-section-title';

export const FAQ_1_TITLE = 'faq-1-title';
export const FAQ_1_TEXT = 'faq-1-text';

export const FAQ_2_TITLE = 'faq-2-title';
export const FAQ_2_TEXT = 'faq-2-text';

export const FAQ_3_TITLE = 'faq-3-title';
export const FAQ_3_TEXT = 'faq-3-text';

export const FAQ_4_TITLE = 'faq-4-title';
export const FAQ_4_TEXT = 'faq-4-text';

export const FAQ_5_TITLE = 'faq-5-title';
export const FAQ_5_TEXT = 'faq-5-text';

export const FAQ_6_TITLE = 'faq-6-title';
export const FAQ_6_TEXT = 'faq-6-text';

export const FAQ_7_TITLE = 'faq-7-title';
export const FAQ_7_TEXT = 'faq-7-text';

export const FAQ_TEXT = 'faq-section-text';
export const FAQ_LINK = 'faq-section-link';
